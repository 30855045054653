import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import "./Autocomplete.css";
import { jsonResponse } from "./stationnamedata";
import { InputGroup, FormControl, Button, Badge} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";

class Autocomplete extends Component {
  static propTypes = {
    suggestions: PropTypes.instanceOf(Array)
  };

  static defaultProps = {
    suggestions: Object.keys(jsonResponse)
  };

  
  constructor(props) {
    super(props);

    this.lableName = this.props.lableName;
    this.state = {
      // The active selection's index
      activeSuggestion: 0,
      // The suggestions that match the user's input
      filteredSuggestions: [],
      // Whether or not the suggestion list is shown
      showSuggestions: false,

      InputError: "",
      // What the user has entered
      userInput: ""
    };
  }

  errorMsg() {
    this.setState({InputError : "Empty"});
  }

  // Event fired when the input value is changed
  onChange = e => {
    const { suggestions } = this.props;
    const userInput = e.currentTarget.value;
    const inputLength = userInput.length;

    this.setState({InputError : ""});

    // Filter our suggestions that don't contain the user's input
    const filteredSuggestions = suggestions.filter(
      suggestion =>
        suggestion.toLowerCase().includes(" " + userInput.toLowerCase()) ||
        suggestion.toLowerCase().substring(0, inputLength) === userInput.toLowerCase()
      // suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    // Update the user input and filtered suggestions, reset the active
    // suggestion and make sure the suggestions are shown
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: true,
      userInput: e.currentTarget.value
    });
  };

  // Event fired when the user clicks on a suggestion
  onClick = e => {
    this.props.handlerFromParent(jsonResponse[e.currentTarget.innerText]);
    // Update the user input and reset the rest of the state
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: e.currentTarget.innerText
    });
  };


  clearInput = e =>{
    this.props.handlerFromParent("");
    this.setState({userInput:""});
    
    //console.log(this.refs.name);
  }

  // Event fired when the user presses a key down
  onKeyDown = e => {
    const { activeSuggestion, filteredSuggestions } = this.state;

    // User pressed the enter key, update the input and close the
    // suggestions
    if (e.keyCode === 13) {
      this.setState({
        activeSuggestion: 0,
        showSuggestions: false,
        userInput: filteredSuggestions[activeSuggestion]
      });
    }
    // User pressed the up arrow, decrement the index
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion - 1 });
    }
    // User pressed the down arrow, increment the index
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion + 1 });
    }
  };

  render() {
    const {
      onChange,
      onClick,
      clearInput,
      onKeyDown,
      state: {
        activeSuggestion,
        filteredSuggestions,
        showSuggestions,
        InputError,
        userInput
      }
    } = this;

    let suggestionsListComponent;

    if (showSuggestions && userInput) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <ul className="suggestions">
            {filteredSuggestions.map((suggestion, index) => {
              let className;

              // Flag the active suggestion with a class
              if (index === activeSuggestion) {
                className = "suggestion-active";
              }

              return (
                <li className={className} key={suggestion} onClick={onClick}>
                  {suggestion}
                </li>
              );
            })}
          </ul>
        );
      } else {
        suggestionsListComponent = (
          <div className="no-suggestions">
            <em>No Stations Found !!</em>
          </div>
        );
      }
    }

    return (
      <Fragment>

        <InputGroup >
        
          <InputGroup.Prepend>
            <InputGroup.Text id="basic-addon1">
              <span role="img" aria-label="train">
                🚆
              </span>
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            placeholder={this.lableName}
            aria-label="Username"
            aria-describedby="basic-addon1"
            type="text"
            onChange={onChange}
            onKeyDown={onKeyDown}
            value={userInput}
          />

          <InputGroup.Append >
            <Button  onClick={clearInput} variant="light" >
              <span aria-hidden="true">&times;</span>
            </Button>
          </InputGroup.Append>

          <InputGroup.Append >
            <p><Badge variant="warning">{InputError}</Badge></p>
          </InputGroup.Append>
          
        </InputGroup>

       
        

        {suggestionsListComponent}
      </Fragment>
    );
  }
}

export default Autocomplete;
