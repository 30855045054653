import React, { useState,useEffect } from "react";
import axios from 'axios'
// import * as http from 'https';
import * as https from 'https';

import { Container, Table, Badge,Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
// import { blockStatement } from "@babel/types";
import ReactTooltip from 'react-tooltip'


// function useMergeState(initialState) {
//     const [state, setState] = useState(initialState);
//     const setMergedState = newState => 
//       setState(prevState => Object.assign({}, prevState, newState)
//     );
//     return [state, setMergedState];
//   }


function Train ( {train,bookingClass,startDate} ) {

    const [Availability,setAvailability] = useState("---");

    // const [train,settrain] = useState(train1);
    // const [bookingClass1,setbookingClass] = useState(bookingClass);
    // const [startDate,setstartDate] = useState(startDate1);


    var postData = {
        quota:"GN",
        trainNumber:train.trainNumber,
         stationFrom:train.fromStnCode,
        stationTo:train.toStnCode,
        bookingClass:bookingClass,
        infodate:startDate



      };
      
      let axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Origin": "*",
            'Accept':'application/json'
        }
      };


      useEffect(() =>{

        if(Availability=== "---"){
            // At request level
			const agenthttps = new https.Agent({  
				rejectUnauthorized: false,
				keepAlive: true
			});
			const agenthttp = new https.Agent({  
				keepAlive: true
			});
			
            axios.post('https://api.biniljacob.com/APISeatStatus', postData, axiosConfig, { httpsAgent: agenthttps },{ httpAgent: agenthttp })
            .then((res) => {
                console.log("Status: ",res.data.status )
                if(res.data.status.length <25){
                    setAvailability(res.data.status);
                    // setbookingClass("HOO");
    
               
                }
              
              // parentCallback(res.data.trainList,bookingClass,passingdate);
              
            })
            .catch((err) => {
              console.log("AXIOS ERROR: ", err);
            })

        }
      
      });
     
      function extractTQAvail(textval){
        var ll = textval.split("::")
        if(ll.length ===2){
          return ll[1]
        }else  if(textval=== "---"){
            return textval
        }
        return "No Data"

    }

    function extractGNAvail(textval){
        var ll = textval.split("::")
        if(ll.length ===2){
          return ll[0]
        }
        return textval

    }
 
        return(
           
            <tr>
                <td>{train.trainNumber}</td>
                <td>{extractGNAvail(Availability)}</td>
                <td>{extractTQAvail(Availability)}</td>
                <td>{train.trainName}</td>
                <td>{train.fromStnCode}</td>
                <td>{train.toStnCode}</td>
                <td>{train.departureTime}</td>
                <td>{train.arrivalTime}</td>
                <td>{train.duration}</td>                
                
            </tr>
           
            );



} 



function ResultTable({userRequest}) {


    function getdate(dat){
        if(dat != null){
            
            return dat.substring(6, 8)+'-'+dat.substring(4, 6)+"-"+dat.substring(0, 4);
        }else{
            return "";
        }
        
    }

    function istheretrain(dat,list1){
        if(dat != null){
            if(list1.length ===0){            
                return "No Trains Available";
            }else {
                return ""
            }
        }else{
            return "";
        }

    }
   
    console.log("HERE",userRequest)

    return(
       
            <Container>
                <h5 style={ {display: "inline"}} ><Badge variant="secondary">Class  {userRequest["bookingClass"] }</Badge></h5>
                {' '}
                <h5 style={ {display: "inline"}}><Badge variant="secondary">Date  {getdate(userRequest["travelDate"])}</Badge></h5>
                {' '}
                <h5 style={ {display: "inline"}}><Badge variant="warning">{istheretrain(userRequest["bookingClass"],userRequest["trainList"])}</Badge></h5>
                 
                

               
                <Table variant="dark" striped bordered hover responsive>
                <ReactTooltip place="top" type="dark" effect="solid"/>
                    <thead>
                        <tr>
                        <th>Train No</th>
                        <th>Availability</th>
                        <th data-tip="Availability shown if booked till the train's final station">Tatkal 
                        <sup><span role="img" aria-label="warning">⚠️</span></sup></th>
                        <th>Train Name</th>
                        <th>From</th>
                        <th>To</th>
                        <th>Dep</th>
                        <th>Arr</th>
                        <th>Duration</th>
                        
                        </tr>
                    </thead>
                    <tbody>
                        
                    



           

                    {
                       
                       userRequest["trainList"].map((train, index) => {
                        // console.log(train)

                        var bookingClass = userRequest["bookingClass"];
                        var travelDate = userRequest["travelDate"];

                        if(train.trainNumber === '0'){
                            console.log("LALA")
                            return <tr key={index}><td colSpan="8">Please Wait <Spinner animation="grow" size="sm" /></td></tr>
                        }

                        if(bookingClass.length<=1||travelDate.length<=1 ||train.trainNumber.length<=1  ){
                            console.log("Skipped")
                            return <div key={index}></div>
                        }else{

                            return <Train key={index} 
                            index={index} train={train} 
                            bookingClass={bookingClass} 
                            startDate={travelDate} />
                        }
                            
                            
                    }
                
                )}
                    </tbody>
                </Table>
            </Container>
                    


    );
}
export default ResultTable;