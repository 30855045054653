import React, { useState } from "react";
import axios from 'axios'
// import * as http from 'https';
import * as https from 'https';


import AC from "./Autocomplete";
import ClassSelect from "./ClassSelect";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Container, Row, Col, Button,Card } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import Clock from 'react-live-clock';



const TatkalForm = ({ parentCallback }) =>{
// function TatkalForm() {

	

	
	

	var input1 = React.createRef();
	var input2 = React.createRef();


	function formatDate( date ){
		// date formating 
		// var date = new Date();
		var month = date.getMonth() + 1;
		month = ("0" + month).slice(-2);
		var day = date.getDate();
		day = ("0" + day).slice(-2);
		var year = date.getFullYear();
		return year+month+day;
	}

	const FormButton = event =>{
		event.preventDefault();
		parentCallback([{trainNumber: "0"}],"","");
		console.log("button")
		var ok = true;
		if(station1 === "") {
			input1.current.errorMsg();
			ok = false;
		}
		if(station2 === "") {
			input2.current.errorMsg();
			ok = false;
		}
		if(ok){
			var passingdate = formatDate( startDate );
			var postData = {
				stationFrom:station1,
				stationTo:station2,
				bookingClass:bookingClass,
				travelDate:formatDate( startDate )

			  };
			  
			let axiosConfig = {
			headers: {
				'Content-Type': 'application/json',
				"Access-Control-Allow-Origin": "*",
				'Accept':'application/json'
			}
			};

			// At request level
			const agenthttps = new https.Agent({  
				rejectUnauthorized: false,
				keepAlive: true
			});
			const agenthttp = new https.Agent({  
				keepAlive: true
			});
			
			
			axios.post('https://api.biniljacob.com/APIListTrain', postData, axiosConfig, { httpsAgent: agenthttps },{ httpAgent: agenthttp })
			.then((res) => {
			parentCallback(res.data.trainList,bookingClass,passingdate);
			
			})
			.catch((err) => {
			console.log("AXIOS ERROR: ", err);
			})



		}
		
		
	};
	const FromStation = data => {
		console.log(data);
		inpstn1(data);
	};

	const ToStation = data => {
		console.log(data);
		inpstn2(data);
	};

	const TrainClass = data => {
		console.log(data.target.value);
		inpcls(data.target.value);
	};

	const handleChange = date => {
		

		inpDate(date);
		console.log(formatDate( date ));
		
	};


	const [startDate, inpDate] = useState(new Date());

	const [station1, inpstn1] = useState("");

	const [station2, inpstn2] = useState("");

	const [bookingClass, inpcls] = useState("SL");


	return (
		<Container>
		<Row>
			<Col md={6}>
			<Row>
			<Col xs={12}>
			<strong> Today: </strong>
			
          <Clock format={'(dddd) DD MMMM,YYYY'} ticking={true} timezone={'Asia/Kolkata'} />
		  <strong>  | </strong>
		  <strong>

		  <Clock format={'HH:mm:ss'} ticking={true} timezone={'Asia/Kolkata'} />

		  </strong>
		  <p><sup>* Queries will not work during tatkal intervals</sup></p>
			</Col>
				<Col xs={12}>
				<div className="FromStation">
					<br />
					<AC ref={input1} handlerFromParent={FromStation} lableName={"To Station"} />
				</div>
				</Col>
				<Col xs={12}>
				<div className="ToStation">
					<br />
					<AC ref={input2} handlerFromParent={ToStation} lableName={"To Station"} />
				</div>
				<br />
				</Col>

				<Col xs={{ span: 6, offset: 3 }} className="d-flex justify-content-center text-center">
				
				<Card>
				
  					<Card.Header><u><p>Select Class</p></u></Card.Header>

				<ClassSelect setClass={TrainClass} />
				</Card>
				
				</Col>

				<Col xs={12} className={"d-none d-sm-block"}>
				<br />
				<div className="d-flex justify-content-center">
					<Button variant="primary" onClick={FormButton} >Submit</Button>
				</div>
				</Col>
			</Row>
			</Col>

			<Col md={6} className="d-flex justify-content-center center-block">
				<Row>
				<Col xs={12}>
					<br />
					<DatePicker
					todayButton={"Today"}
					inline
					dateFormat="dd/MM/yyyy"
					selected={startDate}
					onChange={handleChange}
					/>
				</Col>

				<Col xs={12} className={"d-block d-sm-none"}>
					<br />
				<div className="d-flex justify-content-center">
					<Button variant="primary" onClick={FormButton} >Submit</Button>
				</div>
				</Col>

				</Row>
			
			</Col>
		</Row>
		</Container>
	);
}

export default TatkalForm;
