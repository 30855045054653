import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import dotenv from 'dotenv'
import ReactGA from 'react-ga';

ReactGA.initialize('UA-142205296-1');
ReactGA.pageview(window.location.pathname + window.location.search);
dotenv.config();


ReactDOM.render(<App />, document.getElementById('root'));

