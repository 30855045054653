import React from "react";
import {

  Form,

} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "./ClassSelect.css";


const Radiocomponent = ({ value, setClass }) => (
  <div onChange={setClass.bind(this)} className="radioComponent">
    
{/* <u><p className="text-center" >Select Class</p></u> */}

    <Form.Check
    
      type="radio"
      id={`default-radio-1`}
      label={`SL`}
      value="SL"
      name="trainclass"
      defaultChecked={true}
    />

    <Form.Check
    
      type="radio"
      id={`default-radio-2`}
      label={`3A`}
      value="3A"
      name="trainclass"
      // defaultChecked={value === "3A"}
    />
  </div>
);

export default Radiocomponent;
