import React, { useState } from "react";
import "./App.css";
import TatkalForm from "./Components/TatkalForm";
import ResultTable from  "./Components/ResultTable";
import {Navbar, Container,Nav } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import logo from './train.svg';


// https://scotch.io/tutorials/build-a-react-to-do-app-with-react-hooks-no-class-components
const Todo = ({ todo }) => <div className="todo">{todo.text}</div>;

function TodoForm({ addTodo }) {
  const [value, setValue] = useState("");

  const handleSubmit = e => {
    e.preventDefault();
    if (!value) return;
    addTodo(value);
    setValue("");
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        className="input"
        value={value}
        onChange={e => setValue(e.target.value)}
      />
    </form>
  );
}

function App() {
  const [todos, setTodos] = useState([
    { text: "Take Notes" },
    
  ]);

  const addTodo = text => {
    const newTodos = [...todos, { text }];
    setTodos(newTodos);
  };


  const [userRequest, setUserRequest] = useState({
    trainList: [],
    bookingClass: null,
    travelDate :null,
  });

  // const [trainList, setTrainList] = useState([]);
  // const [bookingClass, setbookingClass] = useState([]);
  // const [travelDate, settravelDate] = useState([]);

  

  const trainlistFX = (trainlistdata,bookingClassdata,travelDatedata) => {
    // do something with value in parent component, like save to state
    // setbookingClass(bookingClassdata);
    // settravelDate(travelDatedata);
    // setTrainList(trainlistdata);

    setUserRequest({
      trainList: trainlistdata,
      bookingClass: bookingClassdata,
      travelDate :travelDatedata,
    });
    

}


  return (
    <div className="app">
      <Container>
        <Navbar bg="light" expand="lg">
        <Navbar.Brand href="#home">
            <img
              src={logo}
              width="30"
              height="30"
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />
             Tatkal Check
         </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
            <Nav className="mr-auto">
              <Nav.Link href="https://biniljacob.com">Home</Nav.Link>
              {/* <Nav.Link href="#">About</Nav.Link> */}
            </Nav>
          </Navbar.Collapse>
         
        </Navbar>
      </Container>
      <br />
      <hr />
      <br />
      <TatkalForm parentCallback={trainlistFX} />
      <br />
      <hr />
      <br />
      <ResultTable userRequest={userRequest} />
      <br />
      <hr />
      <br />

      <div className="todo-list">
        {todos.map((todo, index) => (
          <Todo key={index} index={index} todo={todo} />
        ))}
        <TodoForm addTodo={addTodo} />
      </div>
    </div>
  );
}

export default App;
